import React from 'react';
import { ABOUT_PROJECT_DATA } from "./data/data";
import WhyYouNeedComponent from "./components/WhyYouNeedComponent";
import CommonWrapper from "../../components/CommonWrapper/CommonWrapper";
import { getAnalytics, logEvent } from "firebase/analytics";

const AboutProjectPage = () => {
  const analytics = getAnalytics();

  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P2',
    items: [{ name: 'AboutProjectPage' }]
  });

  const { whyYouNeed } = ABOUT_PROJECT_DATA
  return (
    <CommonWrapper>
      <WhyYouNeedComponent data={whyYouNeed} />
    </CommonWrapper>
  );
};

export default AboutProjectPage;