import { combineReducers } from 'redux';

import unitInfoReducer from './reducers/unitInfoReducer';
import appStoreReducer from './reducers/appStoreReducer';

const rootReducer = combineReducers({
  unitInfo: unitInfoReducer,
  appStore: appStoreReducer,
});

export default rootReducer;
