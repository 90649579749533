import React from 'react';
import { Alert } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

interface IProps {
  isDisplay: boolean,
  severity: AlertColor,
  text: string | JSX.Element,
}

const CalcMessage = ({ isDisplay, severity, text }: IProps) => <>
  {isDisplay &&
    <Alert severity={severity}>{text}</Alert>
  }
</>

export default CalcMessage;