import React from 'react';
import style from './OnlineConsultantPage.module.css'
import { FOOTER_DATA } from "../../components/Footer/data/data";
import { EnvelopeIcon } from "../../components/common/svg";
import MessageWrapper from "../../components/MessageWrapper/MessageWrapper";
import { getAnalytics, logEvent } from "firebase/analytics";

const OnlineConsultantPage = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P4',
    items: [{ name: 'OnlineConsultantPage' }]
  });

  return (
    <div className={style.content}>
      <div className={style.wrapper}>
        <MessageWrapper>
          <>
            <h1 className={style.title}>Online Consultant</h1>
            <div className={style.block}>
              <div className={style.text}>You can contact us on any of these social networks</div>
              <div className={style.contacts}>
                {FOOTER_DATA.socialNetworks.map((el) => {
                  return <div key={el.name} className={style.contact}>
                    <div className={style.logoContainer}>
                      <div className={style.logo}>{el.logo}</div>
                      <div className={style.name}>{el.name}:</div>
                    </div>
                    <div className={style.name}>{el.value}</div>
                  </div>
                })}
              </div>
            </div>
            <div className={style.block}>
              <div className={style.text}>Or write to our email</div>
              <div className={style.contact}>
                <div className={style.logoContainer}>
                  <div className={style.logo}><EnvelopeIcon/></div>
                  <div className={style.name}>Email:</div>
                </div>
                <div className={style.name}>info@rankecon.com</div>
              </div>
            </div>
          </>
        </MessageWrapper>
      </div>
    </div>
  );
};

export default OnlineConsultantPage;