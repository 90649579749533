import React from 'react';
import { Button } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button";

interface IBProps {
  size: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>,
  text: string
  onClick: () => void
}

const CalcButton = ({ text, size, onClick }: IBProps) => <div>
  <Button variant="text" size={size} onClick={onClick}>
    {text}
  </Button>
</div>

export default CalcButton;