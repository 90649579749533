import React from 'react';
import style from "./CalcTextBlock.module.css";

const CalcTextBlock = () => {
  return (
    <div>
      <div className={style.text}>DEFINE THE ENERGY EFFICIENCY CLASS OF YOUR HOME</div>
      <div className={style.text}>Here is a tool with which you can calculate the energy efficiency class of your
        home.
      </div>
      <div className={style.text}>Required filling of all form fields and a set
        of parameters for the calculation.
      </div>
    </div>
  )
}

export default CalcTextBlock;