export const ABOUT_PROJECT_DATA = {
  whyYouNeed: {
    title: 'Why you need RANKECON tool',
    mainText: [
      'Today, an energy efficiency class is assigned to a house after the construction of most new houses. However, during the design and construction phase, the user may want to know in advance the expected energy efficiency class. This can help him improve the design or make improvements before calling an official assessor for an energy efficiency rating. We also know that there is an old fund, even in developed countries, many houses still do not have an assigned official class. Our service will help them take their first step towards being more economical and ecological friendly. This is of particular importance today - when our world is in an energy crisis. ',
      'Argumentation regarding the regulation (certification) of energy efficiency in different countries - we cannot verify the data that the user introduces, in fact, our service is only a tool that helps the user to calculate the energy efficiency of his home.'
    ],
    client: {
      clientsTitle: 'Potential clients:',
      clients: [
        'Owners of houses and apartments who want to learn or improve the class of energy efficiency',
        'People who want to check the declared class of energy efficiency before buying or construction',
        'People who are interested in the calculation methodology and modern technologies in construction and energy consumption',
      ]
    }
  }
};