import * as actions from '../actions/appStoreActions';
import { IInitialState } from '../../Interfaces/Interfaces';
import { COUNTRIES } from "../../components/Header/data/data";

export const initialState = {
  isLoad: true,
  language: COUNTRIES[0],
  isAuth: false,
  isPaid: localStorage.getItem('isPaid') === 'true',
  isVerify: false,
  isNewUser: false,
  isOpenInfoMessage: true,
  user: null,
};

export default function appStoreReducer(state: IInitialState = initialState, action: { type: string, value: any }) {
  switch (action.type) {
    case actions.SET_IS_LOAD:
      return {
        ...state,
        isLoad: action.value,
      };
    case actions.SET_IS_PAID:
      return {
        ...state,
        isPaid: action.value,
      };
    case actions.SET_USER:
      return {
        ...state,
        user: action.value.user,
        isLoad: false,
        isNewUser: action.value.isNewUser,
      };
    case actions.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.value,
      };
    case actions.SET_IS_OPEN_INFO_MESSAGE:
      return {
        ...state,
        isOpenInfoMessage: action.value,
      };
    case actions.SET_NEW_USER:
      return {
        ...state,
        isNewUser: action.value,
      };
    default:
      return state;
  }
}
