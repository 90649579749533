import React from 'react';
import style from './CommonWrapper.module.css'
import MessageWrapper from "../MessageWrapper/MessageWrapper";

interface IProps {
  children: React.ReactNode
}

const CommonWrapper = ({children}: IProps) => {

  return (
    <div className={style.container}>
      <div className={style.content}>
      <MessageWrapper>
        {children}
      </MessageWrapper>
      </div>
    </div>
  );
};

export default CommonWrapper;