export type IFilters = 'infraredThermometers' | 'digitalThermometers' | 'airMeters' | 'temperatureIndicators'

export const SHOP_DATA = {
  filters: ['infraredThermometers','digitalThermometers','airMeters','temperatureIndicators'],
  products: [
    {
      type: 'infraredThermometers',
      name: 'Infrared Thermometer MLG 105 Universal',
      manifacturer: 'Metrologika',
      art: '1-6',
      stock: 'Yes',
      warranty: 36,
      id: 1,
      calibration: 'pcs',
      img: 'https://www.metrologika.com/infrared_thermo/MLG_105_Universal-x.jpg',
      price: 62.00,
      exPrice: 78.00,
      text: 'Infrared thermometer MLG 105 Universal is a high-quality pyrometer with a compact body, enhanced measurement and signal processing capabilities. It allows professional temperature measurements. Minimum and maximum temperature search functions save time. The adjustable emission factor is designed to measure any surface. The best offer in the category of pyrometers to 900, 1000 degrees. Each MLG pyrometer is tested prior to sale and comes with a calibration certificate. Excellent optics 30: 1 is better suited for measuring distant objects.',
      functionality: [
        'Adjustable emissivity (emission)',
        'Two additional modes - search for maximum and minimum temperature',
        'HOLD function - to save the measured values on the display',
        'Laser pointer can be switched off',
        'Display backlight can be switched off',
        'Automatic shut-off function pyrometer',
        'Low battery indication',
        'Selection of scale ℃ / ℉',
      ],
    },
    {
      type: 'digitalThermometers',
      name: 'Digital Thermometer MLG DT3',
      manifacturer: 'Metrologika',
      art: '2-5',
      stock: 'Yes',
      warranty: 36,
      id: 2,
      calibration: 'pcs',
      img: 'https://www.metrologika.com/Thermometer/Termogigrometr-MLG-TH3.jpg',
      price: 23.00,
      exPrice: null,
      text: 'Digital Thermometer MLG DT3 is a three-channel meter with a remote temperature sensor and audible alarm. It is used to measure and monitor the current and temperature limits and humidity.',
      functionality: [
        'Measurement of temperature and humidity + remote temperature sensor',
        'The continuous measurement mode',
        'Function min. and max. evidence',
        'Audible alarm on reaching the set threshold',
      ],
    },
    {
      type: 'airMeters',
      name: 'Thermoanemometer MLG TA60 Mini',
      manifacturer: 'Metrologika',
      art: '3-3',
      stock: 'Yes',
      warranty: 36,
      id: 3,
      calibration: 'pcs',
      img: 'https://www.metrologika.com/Thermoanemometr/Termoanemometr_MLG_TA-.jpg',
      price: 53.00,
      exPrice: null,
      text: 'Thermoanemometer MLG TA60 measures the temperature and velocity of the air flow. The device has a compact size and reliable performance in resistant design. Additional measurement function min/max and freeze current readings allow the use of an anemometer in many situations.',
      functionality: [
        'Modes Min, Max',
        'Freezing readings HOLD function',
        'Dual display',
        'The battery level indication',
        'It operates at low temperatures from -20 ° C',
      ],
    },
    {
      type: 'temperatureIndicators',
      name: 'Custom Designed Temperature Labels',
      manifacturer: 'Metrologika',
      art: '5-8',
      stock: 'under the order',
      warranty: 24,
      id: 4,
      calibration: 'pcs',
      img: 'https://www.metrologika.com/Labels/Custom-Designed-Labels.jpg',
      price: 22.00,
      exPrice: null,
      text: 'In addition to a wide selection of typical temperature indicators Metrologika can creates indicators for your individual order. You can order indicators with the temperature range you need, the number of gradations, special shape, size and color!',
      functionality: [],
    },
    {
      type: 'temperatureIndicators',
      name: 'Temperature Indicators MLG TIL 193-210-223',
      manifacturer: 'Metrologika',
      art: '5-6',
      stock: 'Yes',
      warranty: 24,
      id: 5,
      calibration: 'pack',
      img: 'https://www.metrologika.com/Labels/MLG-TIL-193-210-223.jpg',
      price: 15.00,
      exPrice: null,
      text: 'Irreversible stickers (labels) triggered when heated to specified temperature.',
      functionality: [],
    },
    {
      type: 'temperatureIndicators',
      name: 'Temperature Indicators MLG TIL 232-240-254',
      manifacturer: 'Metrologika',
      art: '5-7',
      stock: 'Yes',
      warranty: 24,
      id: 6,
      calibration: 'pack',
      img: 'https://www.metrologika.com/Labels/MLG-TIL-232-240-254.jpg',
      price: 15.00,
      exPrice: null,
      text: 'Irreversible stickers (labels) triggered when heated to specified temperature.',
      functionality: [],
    },
    {
      type: 'temperatureIndicators',
      name: 'Temperature Indicators MLG TIL 43-54-65',
      manifacturer: 'Metrologika',
      art: '5-1',
      stock: 'Yes',
      warranty: 24,
      id: 7,
      calibration: 'pack',
      img: 'https://www.metrologika.com/Labels/MLG-TIL-43-54-65.jpg',
      price: 15.00,
      exPrice: null,
      text: 'Irreversible stickers (labels) triggered when heated to specified temperature.',
      functionality: [],
    },
  ],
};