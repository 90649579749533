import React from 'react';
import style from "./Header.module.css";
import { Link } from "react-router-dom";
import { RankeconIcon } from "../common/svg";
import { HEADER_DATA } from "./data/data";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";
import { getAuth, signOut } from "@firebase/auth";
import { setIsAuth, setUser } from "../../store/actions/appStoreActions";
import LanguageComponent from "../LanguageComponent/LanguageComponent";

const Header = () => {
  const dispatch = useDispatch();
  const auth = getAuth()
  const { user } = useSelector((store: IStore) => store.default.appStore);
  const { urls } = HEADER_DATA

  // выход
  const handleSignOut = async () => {
    signOut(auth)
      .then(() => {
        dispatch(setUser(null, true))
        dispatch(setIsAuth(false))
      })
  }

  return (
    <div className={style.header}>
      <div className={style.container}>
        <Link className={style.logo} to='/'>
          <RankeconIcon/>
        </Link>
        <div className={style.urlsContainer}>
          {urls.map((el) => {
            return <Link key={el.path} className={style.link} to={el.path}>
              <div className={style.content}>
                {el.name}
              </div>
            </Link>
          })}
        </div>
      </div>
      <div className={style.languageBlock}>
        <LanguageComponent size='small' />
        {user
          ? <div className={style.buttonContainer}>
            <Button
              variant='contained'
              onClick={() => handleSignOut()}
            >
              Logout
            </Button>
          </div>
          : <Link to='/auth' className={style.buttonContainer}>
            <Button variant="contained">Login</Button>
          </Link>}
      </div>
    </div>
  );
};

export default Header;