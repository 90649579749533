import React from 'react';
import style from './LanguageComponent.module.css'
import { FormControl, MenuItem, Select } from "@mui/material";
import { COUNTRIES } from "../Header/data/data";
import { OverridableStringUnion } from "@mui/types";
import { InputBasePropsSizeOverrides } from "@mui/material/InputBase/InputBase";
import { useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";

interface IProps {
  size: OverridableStringUnion<'small' | 'medium', InputBasePropsSizeOverrides>
}

const LanguageComponent = ({ size }: IProps) => {
  const { language } = useSelector((store: IStore) => store.default.appStore);

  return (
    <FormControl className={style.selectLanguageContainer}>
      <Select
        size={size}
        value={language.code}
        // onChange={(el: ) => setLanguage(el.target.value)}
      >
        {COUNTRIES.map((el) => {
          return <MenuItem key={el.code} disabled={el.soon} value={el.code}>
            <div className={style.menuItemContainer}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${el.code.toLowerCase()}.png 2x`}
                alt=""
              />
              <div className={style.codeBlock}>
                {el.code}
                {el.soon && <div className={style.soon}>coming soon</div>}
              </div>

            </div>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  );
};

export default LanguageComponent;