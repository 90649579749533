import React from 'react';
import CalcMessage from "./CalcMessage";
import CalcButton from "./CalcButton";
import { setIsPaid } from "../../../../store/actions/appStoreActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
  isUser: boolean,
  isPaid: boolean,
  isEmailVerified: boolean,
}

const CalcMessageBlock = ({ isUser, isEmailVerified, isPaid }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCreateClick = () => {
    history.push('/auth/create');
  }
  const handlePayClick = () => {
    localStorage.setItem('isPaid', 'true');
    dispatch(setIsPaid(true))
  }
  return (
    <>
      <CalcMessage
        isDisplay={!isUser && !isEmailVerified}
        severity='warning'
        text={<div>
          <div>
            To carry out the necessary calculations, you have chosen the parameters of your house. Not worry if you
            don't have enough some data - you can return to the calculation at any time convenient for you, the data
            will already be restored saved profile. To do this, please register in our service.
          </div>
          <CalcButton text='Create new account' onClick={handleCreateClick} size='small'/>
        </div>}
      />
      <CalcMessage
        isDisplay={isUser && !isEmailVerified}
        severity='warning'
        text='Please verify your mail'
      />
      <CalcMessage
        isDisplay={isUser && isEmailVerified && !isPaid}
        severity='warning'
        text={<div>
          <div>Make a payment to get started</div>
          <CalcButton text='Pay' onClick={handlePayClick} size='small'/>
        </div>
        }
      />
    </>
  )
}

export default CalcMessageBlock;