import { CountryType } from "../../../Interfaces/Interfaces";

export const HEADER_DATA = {
  urls: [
    // {
    //   path: '/profile',
    //   name: 'Profile'
    // },
    {
      path: '/why-you-need',
      name: 'Why you need'
    },
    {
      path: '/shop',
      name: 'Instruments Shop'
    },
    {
      path: '/onlineConsultant',
      name: 'Online Consultant'
    },
    {
      path: '/calculation',
      name: 'Calculation'
    },
  ]
};

export const COUNTRIES: CountryType[] = [
  { code: 'GB' },
  { code: 'IT', soon: true },
  { code: 'FR', soon: true },
  { code: 'DE', soon: true },
  { code: 'ES', soon: true },
];
