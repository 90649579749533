import React, { SyntheticEvent, useState } from 'react';
import { useDispatch } from "react-redux";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification
} from "@firebase/auth";
import { setIsAuth, setUser } from "../../store/actions/appStoreActions";
import style from "./AuthPage.module.css";
import { Alert, Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import CommonWrapper from "../../components/CommonWrapper/CommonWrapper";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AuthPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = getAuth()
  const analytics = getAnalytics();
  const [newLogin, setNewLogin] = useState('');
  const [login, setLogin] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { url } = useRouteMatch();
  const [value, setValue] = useState(url === '/auth/create' ? 1 : 0);

  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P6',
    items: [{ name: 'AuthPage' }]
  });

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // регистрация
  const handleLogin = async () => {
    createUserWithEmailAndPassword(auth, newLogin, newPassword)
      .then((data) => {
        auth.currentUser && sendEmailVerification(auth.currentUser).finally()
        setUserId(analytics,data.user.uid);

        dispatch(setUser({
          _id: data.user.uid,
          name: data.user.displayName || 'userName'
        }, true));
        dispatch(setIsAuth(true))
        history.push('/')
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  // вход
  const handleLoginSignIn = async () => {
    signInWithEmailAndPassword(auth, login, password)
      .then((data) => {
        auth.currentUser && !auth.currentUser?.emailVerified && sendEmailVerification(auth.currentUser).finally()
        setUserId(analytics,data.user.uid);
        dispatch(setUser({
          _id: data.user.uid,
          name: data.user.displayName || 'userName'
        }, false));
        history.push('/')
      })
      .catch((error) => {
        setError(error.message)
      })
  }


  const handleChangeEmail = (value: string, isNew: boolean) => {
    isNew ? setNewLogin(value) : setLogin(value);
  };

  const handleChangeEmailPassword = (value: string, isNew: boolean) => {
    isNew ? setNewPassword(value) : setPassword(value);

    setPassword(value);
  };

  return (
    <CommonWrapper>
      <div className={style.wrapper}>
        <div className={style.content}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Log in" {...a11yProps(0)} />
              <Tab label="Create new account" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className={style.loginBlock}>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                hiddenLabel
                variant="standard"
                label='Enter email'
                value={login}
                onChange={(e) => handleChangeEmail(e.target.value, false)}
              />
              <TextField
                hiddenLabel
                type='password'
                variant="standard"
                label='Enter password'
                value={password}
                onChange={(e) => handleChangeEmailPassword(e.target.value, false)}
              />
              <Button
                variant='contained'
                disabled={!login || !password}
                onClick={() => handleLoginSignIn()}
              >
                Login
              </Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={style.loginBlock}>
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                hiddenLabel
                variant="standard"
                label='Enter email'
                value={newLogin}
                onChange={(e) => handleChangeEmail(e.target.value, true)}
              />
              <TextField
                hiddenLabel
                type='password'
                variant="standard"
                label='Enter password'
                value={newPassword}
                onChange={(e) => handleChangeEmailPassword(e.target.value, true)}
              />
              <Button
                variant='contained'
                disabled={!newLogin || !newPassword}
                onClick={() => handleLogin()}
              >
                Login
              </Button>
            </div>
          </TabPanel>
        </div>
      </div>
    </CommonWrapper>
  );
};

export default AuthPage;