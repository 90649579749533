import React from 'react';
import style from './ShopContainer.module.css';
import { useParams } from "react-router-dom";
import { IFilters, SHOP_DATA } from "../../data/data";
import { Button } from "@mui/material";

const ShopContainer = () => {
  const { topicId } = useParams<{ topicId: IFilters }>();
  const productsWithFilter = SHOP_DATA.products.filter((el) => el.type === topicId)

  const getTitle = () => {
    if (topicId === 'infraredThermometers') {
      return 'Infrared Thermometers'
    }
    if (topicId === 'digitalThermometers') {
      return 'Digital Thermometers'
    }
    if (topicId === 'airMeters') {
      return 'Air Meters'
    }
    if (topicId === 'temperatureIndicators') {
      return 'Temperature Indicators'
    }
  }
  return (
    <div className={style.content}>
      <h1 className={style.title}>{getTitle()}</h1>
      <div className={style.products}>
        {productsWithFilter.map(({
          name,
          warranty,
          calibration,
          img,
          price,
          exPrice,
        }) => {
          return <div className={style.productBlock}>
            <div className={style.imgBlock}>
              <img alt='productImg' className={style.img} src={img}/>
            </div>
            <div className={style.descriptionBlock}>
              <div className={style.name}>{name}</div>
              <div className={style.description}>Warranty: {warranty} months</div>
              <div className={style.description}>Calibration: {calibration}</div>
            </div>
            <div className={style.priceBlock}>
              <div>Price: <span className={style.price}>€{price}</span></div>
              {exPrice && <div className={style.exPrice}>€{exPrice}</div>}
            </div>
            <Button
              className={style.button}
              size='large'
              variant='contained'
            >
              Add to cart
            </Button>
          </div>
        })}
      </div>
    </div>
  );
};

export default ShopContainer;