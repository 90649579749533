export const ITALIAN_CITIES = [
  { label: 'Roma' },
  { label: 'Milano' },
  { label: 'Napoli' },
  { label: 'Torino' },
  { label: 'Brescia' },
  { label: 'Bari' },
  { label: 'Palermo' },
  { label: 'Bergamo' },
  { label: 'Catania' },
  { label: 'Salerno' },
  { label: 'Bologna' },
  { label: 'Firenze' },
  { label: 'Padova' },
  { label: 'Verona' },
  { label: 'Caserta' },
  { label: 'Treviso' },
  { label: 'Varese' },
  { label: 'Monza e della Brianza' },
  { label: 'Vicenza' },
  { label: 'Venezia' },
  { label: 'Genova' },
  { label: 'Lecce' },
  { label: 'Modena' },
  { label: 'Cosenza' },
  { label: 'Perugia' },
  { label: 'Foggia' },
  { label: 'Como' },
  { label: 'Messina' },
  { label: 'Cuneo' },
  { label: 'Latina' },
  { label: 'Taranto' },
  { label: 'Trento' },
  { label: 'Bolzano/Bozen' },
  { label: 'Pavia' },
  { label: 'Reggio nell\'Emilia' },
  { label: 'Reggio di Calabria' },
  { label: 'Udine' },
  { label: 'Sassari' },
  { label: 'Frosinone' },
  { label: 'Ancona' },
  { label: 'Parma' },
  { label: 'Trapani' },
  { label: 'Cagliari' },
  { label: 'Pisa' },
  { label: 'Agrigento' },
  { label: 'Mantova' },
  { label: 'Alessandria' },
  { label: 'Avellino' },
  { label: 'Forlì-Cesena' },
  { label: 'Siracusa' },
  { label: 'Ravenna' },
  { label: 'Barletta-Andria-Trani' },
  { label: 'Lucca' },
  { label: 'Brindisi' },
  { label: 'Chieti' },
  { label: 'Novara' },
  { label: 'Cremona' },
  { label: 'Potenza' },
  { label: 'Pesaro e Urbino' },
  { label: 'Catanzaro' },
  { label: 'Sud Sardegna' },
  { label: 'Ferrara' },
  { label: 'Rimini' },
  { label: 'Lecco' },
  { label: 'Arezzo' },
  { label: 'Livorno' },
  { label: 'Ragusa' },
  { label: 'Pordenone' },
  { label: 'Pescara' },
  { label: 'Viterbo' },
  { label: 'Macerata' },
  { label: 'Teramo' },
  { label: 'L\'Aquila' },
  { label: 'Pistoia' },
  { label: 'Piacenza' },
  { label: 'Benevento' },
  { label: 'Savona' },
  { label: 'Prato' },
  { label: 'Siena' },
  { label: 'Caltanissetta' },
  { label: 'Lodi' },
  { label: 'Rovigo' },
  { label: 'Trieste' },
  { label: 'Grosseto' },
  { label: 'Terni' },
  { label: 'La Spezia' },
  { label: 'Campobasso' },
  { label: 'Asti' },
  { label: 'Imperia' },
  { label: 'Nuoro' },
  { label: 'Ascoli Piceno' },
  { label: 'Belluno' },
  { label: 'Matera' },
  { label: 'Massa-Carrara' },
  { label: 'Sondrio' },
  { label: 'Fermo' },
  { label: 'Biella' },
  { label: 'Vercelli' },
  { label: 'Crotone' },
  { label: 'Enna' },
  { label: 'Rieti' },
  { label: 'Vibo Valentia' },
  { label: 'Verbano-Cusio-Ossola' },
  { label: 'Oristano' },
  { label: 'Gorizia' },
  { label: 'Valle d\'Aosta/Vallée d\'Aoste' },
  { label: 'Isernia' },
];
export const FLOORS = [
  { label: '1 floor without attic' },
  { label: '2 floors or 1 floor with attic' },
  { label: '2 floors in a sectional house' },
  { label: '3 floors' },
  { label: '4 floors' },
  { label: '5 floors' },
  { label: '6-9 floors' },
  { label: '10-15 floors' },
  { label: '16 floors or higher' },
];
export const MATERIALS = [
  { label: 'Foam blocks' },
  { label: 'Brick' },
  { label: 'Wood' },
  { label: 'The concrete' },
];
export const IsAdditionalInsulation = [
  { label: 'Yes' },
  { label: 'No' },
];
export const IsAutomaticControlSystem = [
  { label: 'Yes' },
  { label: 'No' },
];
export const TYPE_WINDOWS = [
  { label: 'Obsolete (one glass)' },
  { label: 'Single chamber (two glasses)' },
  { label: 'Two-chamber (three glasses)' },
];
export const TYPE_SHUTTERS = [
  { label: 'Electrical' },
  { label: 'Mechanical' },
  { label: 'No' },
];

export const AreaOfAllInteriorWalls = {
  tooltipTitle: 'In order to calculate the area of each wall, you need to multiply the height of the wall by its width, the resulting areas of all walls must be summed up and indicate the final value in this field',
  type: 'number',
  label: 'The area of all internal walls, in cm2',
};
export const AreaOfWindowsAndBalconyDoors = {
  tooltipTitle: 'In order to calculate the area of each window (or balcony door), it is necessary to multiply the height of the window by its width, the resulting areas must be summed up and the final value indicated in this field',
  type: 'number',
  label: 'Area of windows and balcony doors, in cm2',
};
export const AreaOfEntranceDoors = {
  tooltipTitle: 'In order to calculate the area of each entrance door, it is necessary to multiply the height of the door by its width, the resulting areas must be summed up and the final value indicated in this field',
  type: 'number',
  label: 'Entrance door area, in cm2',
};
export const AreaOfFloor = {
  tooltipTitle: 'In order to calculate the area of the ceiling, it is necessary to multiply the length and width of the ceiling in each room, the resulting areas must be summed up and the final value indicated in this field',
  type: 'number',
  label: 'Floor area, in cm2',
};
export const AreaOfCeiling = {
  tooltipTitle: 'In order to calculate the area of the ceiling, it is necessary to multiply the length and width of the ceiling in each room, the resulting areas must be summed up and the final value indicated in this field. The ceiling area will be different from the floor area if you do not have an attic floor (the ceiling area in this case will be the inner roof area)',
  type: 'number',
  label: 'Ceiling area, in cm2',
};
export const HeightOfWall = {
  tooltipTitle: 'Measure the height of the walls inside your room.',
  type: 'number',
  label: 'Wall height, cm',
};
export const FloorsOfTheBuilding = {
  type: 'select',
  label: 'Floors of the building',
  menuItem: FLOORS,
};
export const ExternalWallMaterial = {
  type: 'select',
  label: 'External wall material',
  menuItem: MATERIALS,
};
export const WallsHaveAdditionalInsulation = {
  type: 'radio',
  label: 'Do the walls have additional insulation (besides the main material)?',
  menuItem: IsAdditionalInsulation,
};
export const TotalThicknessOfTheOuterWalls = {
  type: 'number',
  label: 'The total thickness of the outer walls in cm.',
};
export const WallInsulationThickness = {
  type: 'number',
  label: 'Wall insulation thickness in cm.',
};
export const WindowType = {
  type: 'select',
  label: 'Window type',
  menuItem: TYPE_WINDOWS,
};
export const ShuttersOnTheWindows = {
  type: 'select',
  label: 'Type of window shutters',
  menuItem: TYPE_SHUTTERS,
};
export const HouseHaveAnAutomaticControlSystem = {
  type: 'radio',
  label: 'Does the room have an automatic control system?',
  menuItem: IsAutomaticControlSystem,
};
export const HousePostalCode = {
  tooltipTitle: 'This is an extremely important parameter used to automatically obtain and calculate the long-term climatic characteristics of your home location: degree days, solar radiation, wind losses',
  type: 'number',
  label: 'Postal code of the premises for which the calculation is made',
};
export const City = {
  type: 'autocomplete',
  label: 'City',
  menuItem: ITALIAN_CITIES,
};
