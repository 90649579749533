import React from 'react';
import style from "./Footer.module.css";
import { Link } from "react-router-dom";
import { FOOTER_DATA } from "./data/data";

const Footer = () => {
  const { urls, socialNetworks, contacts } = FOOTER_DATA
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.contactsContainer}>
          <div className={style.contactsTitle}>
            Contacts
          </div>
          <div className={style.contacts}>
            {contacts.map((el) => {
              return <div key={el.name} className={style.contact}>
                <div className={style.logoContainer}>
                  <div className={style.logo}>{el.logo}</div>
                  <div className={style.name}>{el.name}:</div>
                </div>
                <div className={style.name}>{el.value}</div>
              </div>
            })}
          </div>
          <div className={style.socialNetworks}>
            {socialNetworks.map((el) => {
              return <div key={el.name} className={style.logo}>{el.logo}</div>
            })}
          </div>
          <div className={style.slogan}>
            Save your money
          </div>
        </div>
        <div className={style.linksContainer}>
          {urls.map((el) => {
            return <Link key={el.path} className={style.link} to={el.path}>
              <div className={style.content}>
                {el.name}
              </div>
            </Link>
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;