import React from 'react';
import CommonWrapper from "../../components/CommonWrapper/CommonWrapper";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import ShopContainer from "./components/ShopContainer";
import style from './ShopPage.module.css'
import { getAnalytics, logEvent } from "firebase/analytics";

const shopBlocks = [
  {
    src: 'https://www.metrologika.com/images/cat-1-2.jpg',
    name: 'Infrared Thermometers',
    id: '/infraredThermometers'
  },
  {
    src: 'https://www.metrologika.com/images/cat-2-2.jpg',
    name: 'Digital Thermometers',
    id: '/digitalThermometers'
  },
  {
    src: 'https://www.metrologika.com/images/cat-3-2.jpg',
    name: 'Air Meters',
    id: '/airMeters'
  },
  {
    src: 'https://www.metrologika.com/Labels/Custom-Designed-Labels.jpg',
    name: 'Temperature Indicators',
    id: '/temperatureIndicators'
  }
]
const ShopPage = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P3',
    items: [{ name: 'ShopPage' }]
  });

  const { path, url } = useRouteMatch();
  return (
    <CommonWrapper>
      <div className={style.content}>
        <h1 className={style.title}>
          SHOP
        </h1>
        <Switch>
          <Route path={`${path}/:topicId`} children={<ShopContainer/>}/>
        </Switch>
        <div className={style.shopBlocks}>
          {shopBlocks.map((el) => {
            return <Link
              to={`${url}${el.id}`}
              key={el.id}
              className={style.shopBlock}
            >
              <div className={style.imgBlock}>
                <img alt='shopImg' className={style.img} src={el.src}/>
              </div>
              <div className={style.blockTitle}>{el.name}</div>
            </Link>
          })}
        </div>
      </div>
    </CommonWrapper>
  );
};

export default ShopPage;