import React from 'react';
import { MAIN_DATA } from './data/data';
import MainComponent from "./components/MainComponent";
import style from "./MainPage.module.css";
import MessageWrapper from "../../components/MessageWrapper/MessageWrapper";
import { getAnalytics, logEvent } from "firebase/analytics";


const MainPage = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P1',
    items: [{ name: 'MainPage' }]
  });

  const { mainData } = MAIN_DATA;

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <MessageWrapper>
          <MainComponent data={mainData}/>
        </MessageWrapper>
      </div>
    </div>
  );
};

export default MainPage;