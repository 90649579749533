import React from 'react';
import style from './WhyYouNeedComponent.module.css';

interface Props {
  data: {
    title: string,
    mainText: string[]
    client: {
      clientsTitle: string,
      clients: string[]
    },
  },
}

const WhyYouNeedComponent = ({data}: Props) => {
  const { title, mainText, client } = data
  return (
    <div className={style.content}>
      <h1 className={style.title}>{title}</h1>
      <div className={style.text}>
        {mainText.map((el) => {
          return <div key={el}>{el}</div>
        })}
        <div className={style.clientsBlock}>
          <div className={style.clientsTitle}>{client.clientsTitle}</div>
          <div className={style.potentialClients}>
            {client.clients.map((el) => {
              return <div key={el} className={style.client}>{el}</div>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyYouNeedComponent;