import React, { useState } from 'react';
import style from "./HeaderMobile.module.css";
import { Link } from "react-router-dom";
import { BurgerIcon, RankeconIcon } from "../common/svg";
import { HEADER_DATA } from "./data/data";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";
import { getAuth, signOut } from "@firebase/auth";
import { setUser } from "../../store/actions/appStoreActions";
import LanguageComponent from "../LanguageComponent/LanguageComponent";

const HeaderMobile = () => {
  const dispatch = useDispatch();
  const auth = getAuth()
  const { user } = useSelector((store: IStore) => store.default.appStore);
  const { urls } = HEADER_DATA
  const [isOpen, setIsOpen] = useState(false);

  // выход
  const handleSignOut = () => {
    signOut(auth)
      .then(() => dispatch(setUser(null, true)))
  }

  return (
    <div className={style.header}>
      <div className={style.container}>
        <div className={style.logo} onClick={() => setIsOpen(!isOpen)}>
          <BurgerIcon/>
        </div>
        <Link
          to='/'
          className={style.brandText}
          onClick={() => setIsOpen(false)}
        >
          Rankecon
        </Link>
        <div className={style.languageBlock}>
          <LanguageComponent size='small' />
          <Link className={style.logo} to='/'>
            <RankeconIcon/>
          </Link>
        </div>
      </div>
      {isOpen &&
        <div className={style.menuContainer}>
          <div className={style.urlsContainer}>
            <Link className={style.link} to='/' onClick={() => setIsOpen(!isOpen)}>
              <div className={style.content}>
                Main
              </div>
            </Link>
            {urls.map((el) => {
              return <Link key={el.path} className={style.link} to={el.path} onClick={() => setIsOpen(!isOpen)}>
                <div className={style.content}>
                  {el.name}
                </div>
              </Link>
            })}
            {user
              ? <div onClick={() => {
                handleSignOut()
                setIsOpen(!isOpen)
              }} className={style.link}>Logout</div>
              : <Link
                onClick={() => setIsOpen(!isOpen)}
                to='/auth' className={style.link}
              >
                Login
              </Link>}
          </div>
          <div className={style.wrapper} onClick={() => setIsOpen(!isOpen)}/>
        </div>}
    </div>
  );
};

export default HeaderMobile;