import React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";
import style from "./AccountPage.module.css";
import { CameraIcon } from "../../components/common/svg";
import CommonWrapper from "../../components/CommonWrapper/CommonWrapper";
import { getAnalytics, logEvent } from "firebase/analytics";

const AccountPage = () => {
  const { user } = useSelector((store: IStore) => store.default.appStore);
  const analytics = getAnalytics();

  logEvent(analytics, 'select_content', {
    content_type: 'image',
    content_id: 'P7',
    items: [{ name: 'AccountPage' }]
  });

  return (
    <CommonWrapper>
      <div className={style.content}>
        <CameraIcon className={style.img}/>
        <div className={style.infoBlock}>
          <div className={style.textBlock}>
            <div className={style.text}>Id</div>
            <div className={style.text}>{user?._id}</div>
          </div>
          <div className={style.textBlock}>
            <div className={style.text}>Name:</div>
            <div className={style.text}>{user?.name}</div>
          </div>
        </div>
      </div>
    </CommonWrapper>
  );
};

export default AccountPage;