import React, { useEffect, useRef, useState } from 'react';
import { EfficiencyClassEnum } from '../../../../constants/const';
import style from './ResultBlock.module.css';

const ResultBlock = () => {
  const [seconds, setSeconds] = useState(30);
  const intervalRef = useRef<any>();

  const classEffect = 2
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds((sec) => sec - 1);
    }, 100);
    return () => clearInterval(intervalRef.current);

  }, []);


  const cancelInterval = () => {
    if (seconds < classEffect) {
      clearInterval(intervalRef.current);
    }
  };

  cancelInterval();

  const getColor = (efficiencyClass: EfficiencyClassEnum) => {
    if (efficiencyClass === EfficiencyClassEnum.G) {
      return '#eb0e0e'
    }
    if (efficiencyClass === EfficiencyClassEnum.F) {
      return '#f58310'
    }
    if (efficiencyClass === EfficiencyClassEnum.E) {
      return '#f7c60f'
    }
    if (efficiencyClass === EfficiencyClassEnum.D) {
      return '#fcfb15'
    }
    if (efficiencyClass === EfficiencyClassEnum.C) {
      return '#9bff15'
    }
    if (efficiencyClass === EfficiencyClassEnum.B) {
      return '#14b64d'
    }
    return '#178242'
  }

  const getEfficiencyClass = (num: number) => {
    const percent = num % 7
    if (percent === 6) {
      return EfficiencyClassEnum.G
    }
    if (percent === 5) {
      return EfficiencyClassEnum.F
    }
    if (percent === 4) {
      return EfficiencyClassEnum.E
    }
    if (percent === 3) {
      return EfficiencyClassEnum.D
    }
    if (percent === 2) {
      return EfficiencyClassEnum.C
    }
    if (percent === 1) {
      return EfficiencyClassEnum.B
    }
    return EfficiencyClassEnum.A
  }

  return (
    <div className={style.resultBlock}>
      <div className={style.text}>Your energy efficiency class:</div>
      <div className={style.result}
           style={{ color: getColor(getEfficiencyClass(seconds)) }}>{getEfficiencyClass(seconds)}</div>
    </div>
  );
};

export default ResultBlock;