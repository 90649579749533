import * as actions from '../actions/unitInfoActions';

interface IInitialState {
  isOpen: boolean,
}

export const initialState = {
  isOpen: false,
};

export default function unitInfoReducer(state: IInitialState = initialState, action: { type: string, value: any }) {
  switch (action.type) {
    case actions.SET_IS_OPEN:
      return {
        ...state,
        isOpen: action.value,
      };
    default:
      return state;
  }
}
