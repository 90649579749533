import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import { getAuth } from "@firebase/auth";
import CloseIcon from '@mui/icons-material/Close';
import { setIsOpenInfoMessage, setNewUser } from "../../store/actions/appStoreActions";

interface IProps {
  children: React.ReactNode
}

const MessageWrapper = ({ children }: IProps) => {
  const { isAuth, isNewUser, isOpenInfoMessage} = useSelector((store: IStore) => store.default.appStore);
  const auth = getAuth()
  const dispatch = useDispatch();

  useEffect(() => {
  }, [isAuth])

  return (
    <>

      {isNewUser && auth.currentUser && <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              dispatch(setNewUser(false));
            }}
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        }
        severity="success"
      >Thank you for registering with our service!</Alert>}
      {auth.currentUser && isOpenInfoMessage && <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              dispatch(setIsOpenInfoMessage(false));
            }}
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        }
        severity="info"
      >
        <AlertTitle>Important message:</AlertTitle>
        Our service is currently <strong>under development</strong>, due to you can get early access as an explorer for
        only <strong>32 euros</strong> instead of the standard 64 euros! This cost includes various calculations of the
        three houses and eternal access
        to editing the entered data. The working stage of the service starts on <strong>August 15, 2024.</strong> We
        will be very grateful to you for providing an <strong>feedback!</strong>
      </Alert>}
      {children}
    </>
  );
};

export default MessageWrapper;