import {
  EnvelopeIcon,
  FacebookMessengerIcon,
  InstagramIcon,
  SkypeIcon,
  TelegramIcon,
  WhatsappIcon
} from "../../common/svg";
import React from "react";

export const FOOTER_DATA = {
  socialNetworks: [
    {
      name: 'WhatsApp',
      value: 'wa.me/',
      logo: <WhatsappIcon/>,
    },
    {
      name: 'Telegram',
      value: 't.me/',
      logo: <TelegramIcon/>,
    },
    {
      name: 'Instagram',
      value: 'www.instagram.com/',
      logo: <InstagramIcon/>,
    },
    {
      name: 'Facebook',
      value: 'www.facebook.com/',
      logo: <FacebookMessengerIcon/>,
    },
  ],
  contacts: [
    {
      name: 'E-mail',
      value: 'info@rankecon.com',
      logo: <EnvelopeIcon/>,
    },
    {
      name: 'Skype',
      value: 'rankecon',
      logo: <SkypeIcon/>,
    },
  ],
  urls: [
    // {
    //   path: '/profile',
    //   name: 'Profile'
    // },
    {
      path: '/why-you-need',
      name: 'Why you need'
    },
    {
      path: '/shop',
      name: 'Instruments Shop'
    },
    // {
    //   path: '/partners',
    //   name: 'Partners'
    // },
    {
      path: '/onlineConsultant',
      name: 'Online Consultant'
    },
  ]
};