import React, { useEffect } from 'react';
import style from './App.module.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { setIsLoad, setUser } from "./store/actions/appStoreActions";
import { useDispatch } from 'react-redux';
import AuthPage from "./pages/AuthPage/AuthPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AboutProjectPage from "./pages/AboutProjectPage/AboutProjectPage";
import AccountPage from "./pages/AccountPage/AccountPage";
import ShopPage from "./pages/ShopPage/ShopPage";
import OnlineConsultantPage from "./pages/OnlineConsultantPage/OnlineConsultantPage";
import CalcPage from "./pages/CalcPage/CalcPage";
import HeaderMobile from "./components/HeaderMobile/HeaderMobile";
import ScrollToTop from "./components/ScrollToTop";


const App = () => {
  const dispatch = useDispatch();
  const auth = getAuth()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(setUser({
          _id: user.uid,
          name: user.displayName || 'userName'
        }, false));
      }
      dispatch(setIsLoad(false));
    });
  }, [auth, dispatch])

  return (
    <div className={style.app}>
      <Router>
        <Header/>
        <ScrollToTop />
        <HeaderMobile/>
        <div className={style.wrapper}>
          <Switch>
            <Route path='/calculation' children={<CalcPage/>}/>
            <Route path='/auth/create' children={<AuthPage/>}/>
            <Route path='/auth' children={<AuthPage/>}/>
            <Route path='/why-you-need' children={<AboutProjectPage/>}/>
            <Route path='/onlineConsultant' children={<OnlineConsultantPage/>}/>
            <Route path='/profile' children={<AccountPage/>}/>
            <Route path='/shop' children={<ShopPage/>}/>
            <Route path='/' children={<MainPage/>}/>
            <Route path="*" children={<div>404 NOT FOUND</div>}/>
          </Switch>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
