import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import * as reducers from './store/index';
import thunk from 'redux-thunk';
import firebase from "firebase/compat/app";
import { createTheme, ThemeProvider } from "@mui/material";
import { getAnalytics } from "firebase/analytics";

const store = createStore(combineReducers(reducers), applyMiddleware(thunk));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const app = firebase.initializeApp({
  apiKey: "AIzaSyB66HjTXLa0wnGde_vm4MiXdasvJ5g85vg",
  authDomain: "rankecon-2ca56.firebaseapp.com",
  projectId: "rankecon-2ca56",
  storageBucket: "rankecon-2ca56.appspot.com",
  messagingSenderId: "780646989159",
  appId: "1:780646989159:web:c297b9f70dcdbf4e3e83c2",
  measurementId: "G-NFF3VT6JWK"
});

const analytics = getAnalytics(app);
console.log(analytics)

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: [
      'Gill Sans',
    ].join(','),
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
        <Provider store={store}>
          <App/>
        </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
