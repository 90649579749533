import React, { useState } from 'react';
import style from './CalcPage.module.css'
import CommonWrapper from "../../components/CommonWrapper/CommonWrapper";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { IStore } from "../../Interfaces/Interfaces";
import { getAuth } from "@firebase/auth";
import FieldsBlock from "./components/FieldsBlock";
import CalcMessageBlock from "./components/CalcMessageBlock";
import ResultBlock from "./components/ResultBlock";
import CalcTextBlock from "./components/CalcTextBlock";


const CalcPage = () => {
  const { isPaid, user } = useSelector((store: IStore) => store.default.appStore);
  const [isOpen, setIsOpen] = useState(false);
  const auth = getAuth()
  const isDisable = !auth.currentUser?.emailVerified || !isPaid


  return (
    <CommonWrapper>
      <div className={style.content}>
        <h1 className={style.title}>Calculation</h1>
        <CalcMessageBlock isPaid={isPaid} isUser={!!user} isEmailVerified={!!auth.currentUser?.emailVerified} />
        <CalcTextBlock/>
        <FieldsBlock isDisable={isDisable}/>
        <Button
          variant='contained'
          disabled={isDisable}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          Lets Calculation
        </Button>
        {isOpen && <ResultBlock/>}
      </div>
    </CommonWrapper>
  )
};

export default CalcPage;