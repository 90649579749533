import { useEffect } from "react";

import { useLocation } from "react-router-dom";

/** Скрол наверх при изменении маршрута */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    /** TODO использовать react-scroll для анимации */
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
