import React from 'react';
import HelpIcon from "@mui/icons-material/Help";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import style from './Tooltip.module.css'

interface IProps {
  title: string
}

const CustomTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} children={children}/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12
  },
});

const TooltipContainer = ({ title }: IProps) => {
  return (
    <CustomTooltip title={title}><HelpIcon className={style.icon}/></CustomTooltip>
  );
};

export default TooltipContainer;