import { IUser } from "../../Interfaces/Interfaces";

export const SET_IS_LOAD = 'SET_IS_LOAD';
export const SET_IS_AUTH = 'SET_IS_VERIFY';
export const SET_IS_PAID = 'SET_IS_PAID';
export const SET_USER = 'SET_USER';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_IS_OPEN_INFO_MESSAGE = 'SET_IS_OPEN_INFO_MESSAGE';

export const setIsLoad = (isLoad: boolean) => {
  return ({ type: SET_IS_LOAD, value: isLoad });
};

export const setIsAuth = (isLoad: boolean) => {
  return ({ type: SET_IS_AUTH, value: isLoad });
};

export const setUser = (user: IUser | null, isNewUser: boolean) => {
  return ({ type: SET_USER, value: { user, isNewUser } });
};

export const setNewUser = (isNewUser: boolean) => {
  return ({ type: SET_NEW_USER, value: isNewUser });
};

export const setIsOpenInfoMessage = (isNewUser: boolean) => {
  return ({ type: SET_IS_OPEN_INFO_MESSAGE, value: isNewUser });
};

export const setIsPaid = (isPaid: boolean) => {
  return ({ type: SET_IS_PAID, value: isPaid });
};

