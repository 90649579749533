export const HEADER_DATA = {
  urls: [
    // {
    //   path: '/profile',
    //   name: 'Profile'
    // },
    {
      path: '/why-you-need',
      name: 'Why you need'
    },
    {
      path: '/shop',
      name: 'Instruments Shop'
    },
    {
      path: '/onlineConsultant',
      name: 'Online Consultant'
    },
    {
      path: '/calculation',
      name: 'Calculation'
    },
  ]
};