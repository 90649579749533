import React from 'react';
import style from './FieldsBlock.module.css';
import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import TooltipContainer from "../../../../components/Tooltip/Tooltip";
import {
  AreaOfAllInteriorWalls,
  AreaOfCeiling,
  AreaOfEntranceDoors,
  AreaOfFloor,
  AreaOfWindowsAndBalconyDoors,
  ExternalWallMaterial,
  FloorsOfTheBuilding,
  HeightOfWall, HouseHaveAnAutomaticControlSystem, HousePostalCode, ShuttersOnTheWindows,
  TotalThicknessOfTheOuterWalls, WallInsulationThickness,
  WallsHaveAdditionalInsulation, WindowType
} from "../../data/data";

interface Props {
  isDisable: boolean,
}

const FieldsBlock = ({ isDisable }: Props) => {
  return (
    <div className={style.textFieldsBlock}>
      <div className={style.textFieldBlocks}>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{ endAdornment: <TooltipContainer title={AreaOfAllInteriorWalls.tooltipTitle}/> }}
            type={AreaOfAllInteriorWalls.type}
            label={AreaOfAllInteriorWalls.label}
            disabled={isDisable}
            // value={buildingType}
            // onChange={(e) => setBuildingType(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{
              endAdornment: <TooltipContainer title={AreaOfWindowsAndBalconyDoors.tooltipTitle}/>
            }}
            type={AreaOfWindowsAndBalconyDoors.type}
            label={AreaOfWindowsAndBalconyDoors.label}
            disabled={isDisable}
            // value={numberOfStoreys}
            // onChange={(e) => setNumberOfStoreys(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{ endAdornment: <TooltipContainer title={AreaOfEntranceDoors.tooltipTitle}/> }}
            type={AreaOfEntranceDoors.type}
            label={AreaOfEntranceDoors.label}
            disabled={isDisable}
            // value={heatedBuildingVolume}
            // onChange={(e) => setHeatedBuildingVolume(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            disabled={isDisable}
            InputProps={{ endAdornment: <TooltipContainer title={AreaOfFloor.tooltipTitle}/> }}
            type={AreaOfFloor.type}
            label={AreaOfFloor.label}
            // value={heatedBuildingVolume}
            // onChange={(e) => setHeatedBuildingVolume(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{ endAdornment: <TooltipContainer title={AreaOfCeiling.tooltipTitle}/> }}
            type={AreaOfCeiling.type}
            label={AreaOfCeiling.label}
            disabled={isDisable}
            // value={heatedBuildingVolume}
            // onChange={(e) => setHeatedBuildingVolume(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{ endAdornment: <TooltipContainer title={HeightOfWall.tooltipTitle}/> }}
            type={HeightOfWall.type}
            label={HeightOfWall.label}
            disabled={isDisable}
            // value={heatedBuildingVolume}
            // onChange={(e) => setHeatedBuildingVolume(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <FormControl fullWidth>
            <InputLabel>{FloorsOfTheBuilding.label}</InputLabel>
            <Select
              label={FloorsOfTheBuilding.label}
              disabled={isDisable}
              // value={age}
              // onChange={handleChange}
            >
              {FloorsOfTheBuilding.menuItem.map((el) => {
                return <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className={style.textFieldBlock}>
          <FormControl fullWidth>
            <InputLabel>{ExternalWallMaterial.label}</InputLabel>
            <Select
              disabled={isDisable}
              label={ExternalWallMaterial.label}
              // value={age}
              // onChange={handleChange}
            >
              {ExternalWallMaterial.menuItem.map((el) => {
                return <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={style.textFieldBlocks}>
        <div className={style.textFieldBlock}>
          <FormLabel>{WallsHaveAdditionalInsulation.label}</FormLabel>
          <RadioGroup row>
            {WallsHaveAdditionalInsulation.menuItem.map((el) => {
              return <FormControlLabel
                key={el.label}
                disabled={isDisable}
                value={el.label}
                control={<Radio/>}
                label={el.label}
              />
            })}
          </RadioGroup>
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            disabled={isDisable}
            type={TotalThicknessOfTheOuterWalls.type}
            label={TotalThicknessOfTheOuterWalls.label}
            // value={insulationThickness}
            // onChange={(e) => setInsulationThickness(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            disabled={isDisable}
            type={WallInsulationThickness.type}
            label={WallInsulationThickness.label}
            // value={insulationThickness}
            // onChange={(e) => setInsulationThickness(e.target.value)}
          />
        </div>
        <div className={style.textFieldBlock}>
          <FormControl
            disabled={isDisable}
            fullWidth
          >
            <InputLabel>{WindowType.label}</InputLabel>
            <Select
              label={WindowType.label}
              // value={age}
              // onChange={handleChange}
            >
              {WindowType.menuItem.map((el) => {
                return <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className={style.textFieldBlock}>
          <FormControl
            disabled={isDisable}
            fullWidth
          >
            <InputLabel>{ShuttersOnTheWindows.label}</InputLabel>
            <Select
              label={ShuttersOnTheWindows.label}
              // value={age}
              // onChange={handleChange}
            >
              {ShuttersOnTheWindows.menuItem.map((el) => {
                return <MenuItem key={el.label} value={el.label}>{el.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className={style.textFieldBlock}>
          <FormLabel>{HouseHaveAnAutomaticControlSystem.label}</FormLabel>
          <RadioGroup row>
            {HouseHaveAnAutomaticControlSystem.menuItem.map((el) => {
              return <FormControlLabel
                key={el.label}
                disabled={isDisable}
                value={el.label}
                control={<Radio/>}
                label={el.label}
              />
            })}
          </RadioGroup>
        </div>
        <div className={style.textFieldBlock}>
          <TextField
            InputProps={{ endAdornment: <TooltipContainer title={HousePostalCode.tooltipTitle}/> }}
            type={HousePostalCode.type}
            label={HousePostalCode.label}
            disabled={isDisable}
            // value={areaEnclosingStructures}
            // onChange={(e) => setAreaEnclosingStructures(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldsBlock;