import React from 'react';
import style from './MainComponent.module.css';
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

interface Props {
  data: {
    title: string,
    subtitle: string,
    text: string,
    list: string[]
  },
}

const MainComponent = ({ data }: Props) => {
  const { title, subtitle, text, list } = data;
  const history = useHistory();

  return (
    <div className={style.content}>
      <h1 className={style.title}>{title}</h1>
      <div className={style.subtitle}>{subtitle}</div>
      <div className={style.text}>{text}</div>
      <div className={style.listBlock}>
        {list.map((el) => {
          return <div key={el} className={style.list}>{el}</div>
        })}
      </div>
      <Button
        onClick={() => {
          history.push('/calculation');
        }}
        className={style.button}
        size='large'
        variant='contained'
      >
        Start Calculation!
      </Button>
    </div>
  );
};

export default MainComponent;